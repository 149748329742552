<template>
    <main-layout
        itemMenuActive="42"
        tituloMenu="Informe de análisis de resultados"
    >
      <div class="container is-full">
        <div>
          <br/>
          <form v-on:submit.prevent="obtenerReporte">
            <div class="columns is-mobile is-multiline is-centered">
              <div class="column is-12">
                <div class="card">
                  <div class="card-content">
                    <b-field label="">
                      <h1 style="margin-right: 1rem; line-height: 2.5rem">
                        Filtrar:
                      </h1>
                      <b-datepicker
                          v-model="rango_fecha"
                          placeholder="Fecha Inicial"
                          icon="calendar-today"
                          trap-focus
                          rounded
                          required
                          range
                          :max-date="new Date()"
                      >
                      </b-datepicker>
                      <b-button native-type="submit" type="is-info"
                      >Consultar
                      </b-button
                      >
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <br/>
          <div class="columns is-desktop is-multiline is-centered">
            <div class="column is-12" v-if="seriesRosquilla.length > 0">
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <h4 class="subtitle is-4">
                      Totales de Transacciones Fallidas en Transacción de
                      Onboarding Digital
                    </h4>
                    <apexchart
                        type="donut"
                        :height="300"
                        :options="chartOptionsRosquilla"
                        :series="seriesRosquilla"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-12" v-if="seriesTorta.length > 0">
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <h4 class="subtitle is-4">
                      Transacciones Fallidas en Transacción de Onboarding Digital por Usuario
                    </h4>
                    <apexchart
                        type="bar"
                        :height="300"
                        :options="chartOptionsBar"
                        :series="seriesBar"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br/>
          <h3 class="subtitle is-3">
            Tipos de Eventos en Transacción de Onboarding
          </h3>
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <TableCard messageEmpty="Sin datos"
                             :columns="columns" :info="dataTableTypeImage"
                             detailKey="counter" :tableConfig="tableConfig"
                             :haveFilter="false"></TableCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </main-layout>
  </template>
  
  <script>
  import MainLayout from "@/components/Layout/MainLayout";
  import apexchart from "vue-apexcharts";
  import {mapActions} from "vuex";
  import {
    COLUMNS_ONBOARDING_EVENT_TYPES_TABLE
  } from "../../assets/config/constants/TableConstants";
  import {VU_SOP_ERROR_LABELS} from "../../assets/config/constants/GeneralConstants";
  //import GraficoBarras from "@/components/Graficos/GraficoBarras";
  //import TableDataUserError from "@/components/Graficos/TableDataUserError";
  let colores = [
    "#E5707E",
    "#F1948A",
    "#AF7AC5",
    "#D2B4DE",
    "#84B6F4",
    "#79B5E1",
    "#3ABFB0",
    "#00D9C0",
    "#F7DC6F",
    "#F8C471",
    "#F0B27A",
  ];
  
  export default {
    name: "ReporteErrores",
    components: {
      MainLayout,
      apexchart,
      TableCard: () => import("../../components/cards/TableCard")
    },
    data() {
      return {
        counter: 0,
        rango_fecha: [],
        dataInfo: [],
        dataUsuario: {
          nombre: [],
          cantidad: [],
        },
        dataTableTypeImage: [],
        isLoading: false,
        hasMobileCards: true,
        tableConfig: {
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: false,
        },
        columns: COLUMNS_ONBOARDING_EVENT_TYPES_TABLE,
        seriesRosquilla: [],
        chartOptionsRosquilla: {
          chart: {
            type: "donut",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "50%",
              },
            },
          },
          labels: [],
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
          },
          colors: colores,
          legend: {
            //width: 200,
            position: "right",
            offsetY: 0,
            fontSize: "11px",
            fontWeight: 400,
            markers: {
              width: 12,
              height: 12,
              strokeWidth: 0,
              fillColors: undefined,
              radius: 12,
              offsetX: 0,
              offsetY: 0,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        seriesTorta: [],
        chartOptionsTorta: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
          },
          colors: colores,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        seriesBar: [
          {
            data: [],
          },
        ],
        chartOptionsBar: {
          chart: {
            height: 200,
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              barHeight: "60%",
              distributed: true,
              horizontal: false,
            },
          },
          colors: colores,
          dataLabels: {
            position: "top",
            fontFamily: "Roboto, Arial, sans-serif",
            enabled: false,
            textAnchor: "start",
            style: {
              colors: ["#000"],
              fontWeight: "normal",
            },
            offsetX: 0,
          },
          legend: {
            //width: 250,
            position: "bottom",
            horizontalAlign: "left",
            offsetY: 0,
            fontSize: "11px",
          },
          yaxis: {
            labels: {
              show: true,
              textAnchor: "start",
            },
          },
          xaxis: {
            categories: [],
            position: "bottom",
            labels: {
              show: false,
            },
          },
          tooltip: {
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (val, opt) {
                  return opt.w.globals.labels[opt.dataPointIndex] + ":  ";
                },
              },
            },
          },
        },
      };
    },
    mounted() {
      this.obtenerRangoFecha();
    },
    methods: {
      obtenerRangoFecha() {
        if (
            this.rango_fecha == null ||
            this.rango_fecha[0] == null ||
            this.rango_fecha[1] == null
        ) {
          return;
        }
  
        const options = {
          method: "get",
          uri: "boards/getRangeDate",
        };
        this._axios(options)
            .then((response) => {
              this.minDate = new Date(response.data[0]);
              this.maxDate = new Date(response.data[1]);
            })
            .catch((error) => {
              this.isLoadingTable = false;
              console.log(error);
            })
            .finally(() => this.cancelarLoading());
      },
      obtenerReporte() {
        this.counter = 0;
        this.isLoading = true;
        const options = {
          method: "post",
          // uri: "boards/reportError",
          uri: "boards/reportError_New",
          data: {
            FechaInicial:
                this.rango_fecha == null ? "0001-01-01" : this.rango_fecha[0],
            FechaFinal:
                this.rango_fecha == null ? "0001-01-01" : this.rango_fecha[1],
            Fecha: new Date(),
            tipo: 'fin'
          },
        };
  
        //definicion constante grafica barras
        const newDataBarras = [];
  
        this.dataTableTypeImage.length = 0;
  
        //Grafica
        this.seriesRosquilla.length = 0;
        this.chartOptionsRosquilla.labels.length = 0;
  
        this.chartOptionsTorta.labels.length = 0;
        this.seriesTorta.length = 0;
  
        //this.categorias.length = 0;
        this.chartOptionsBar.xaxis.categories.length = 0;
        //this.seriesBar.data.length = 0;
  
        this._axios(options)
            .then((res) => {
              res.data.listTotalError.forEach((element) => {
                //Llenar grafica rosquilla
                if (VU_SOP_ERROR_LABELS.get(element.tipo_error) === undefined) {
                  this.chartOptionsRosquilla.labels.push(element.tipo_error);
                }else{
                  this.chartOptionsRosquilla.labels.push(
                  VU_SOP_ERROR_LABELS.get(element.tipo_error)
                  );
                }
                //this.chartOptionsRosquilla.labels.push(element.tipo_error);
                this.seriesRosquilla.push(element.cantidad);
  
                //this.categorias.push(element.descriptionCodeResponse);
              });
  
              res.data.listErrorUser.forEach((element) => {
                //Llenar grafica torta
                if (VU_SOP_ERROR_LABELS.get(element.descriptionCodeResponse) === undefined) {
                  this.chartOptionsTorta.labels.push(element.descriptionCodeResponse);
                }else{
                  this.chartOptionsTorta.labels.push(
                  VU_SOP_ERROR_LABELS.get(element.descriptionCodeResponse)
                  );
                }
                this.seriesTorta.push(element.counterResponse);
  
                //llenar grafica barras
                newDataBarras.push(element.counterResponse);
                if (VU_SOP_ERROR_LABELS.get(element.descriptionCodeResponse) === undefined) {
                  this.chartOptionsBar.xaxis.categories.push(element.descriptionCodeResponse);
                }else{
                  this.chartOptionsBar.xaxis.categories.push(
                      VU_SOP_ERROR_LABELS.get(element.descriptionCodeResponse)
                  );
                }
              });
  
              this.seriesBar = [{}];
              this.seriesBar = [
                {
                  data: newDataBarras,
                },
              ];
  
              res.data.listUserImagenType.forEach((element) => {
                this.counter++;
                element.counter = this.counter;
                this.dataTableTypeImage = res.data.listUserImagenType;
              });
  
              this.dataInfo = res.data.listPrueba;
            })
            .catch((e) => {
              this.isLoading = false;
              console.log(e);
            })
            .finally(() => this.cancelarLoading());
      },
      cancelarLoading() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      },
      convertFecha: function (fecha) {
        let date = new Date(fecha).toISOString().slice(0, 10);
        return date;
      },
      ...mapActions(["_axios"]),
    },
  };
  </script>
  
  <style>
  .button.is-primary.is-outlined {
    background-color: transparent;
    border-color: #00522a;
    color: #00522a;
  }
  
  .button.is-primary.is-outlined:hover,
  .button.is-primary.is-outlined.is-hovered,
  .button.is-primary.is-outlined:focus,
  .button.is-primary.is-outlined.is-focused {
    background-color: rgba(0, 82, 42, 0.8);
    border-color: #00522a;
    color: #fff;
  }
  </style>